import axios from 'axios'
import { request, response } from './interceptors'

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URI,
  timeout: import.meta.env.PROD ? 30000 : 0,
  withCredentials: true
})

// For getting imgproxy sizes
// should be refactored on the backend
const convertInstance = axios.create({
  baseURL: import.meta.env.VITE_CONVERT_BASE_URI,
  timeout: 0,
  withCredentials: true
})

instance.interceptors.request.use(request.success, request.failure)

instance.interceptors.response.use(response.success, response.failure)

export { axios, instance, uploadInstance, convertInstance }
