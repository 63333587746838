/* Use the axios instance with auth interceptor */
import { axios, instance, convertInstance, uploadInstance } from '../axios'
import { AxiosProgressEvent } from 'axios'

export async function getImageProxySizes(medium: IMedium): Promise<IImageProxySizesResponse> {
  const res = await convertInstance.post<IImageProxySizesResponse>('/api/v2/media/image/sizes',
    {
      url: medium.href,
    })
  return res.data
}

export async function uploadToSignedUrl(upload: IUploadBase, progressCallback: (progressEvent: AxiosProgressEvent) => void): Promise<IFileUploadResponse> {
  const filename = upload.url
  const res = await instance.get<IFileUploadResponse>('/api/v2/media/signed-upload-url', { params: { filename } })
  await axios.put(res.data.url, upload.file, {
    headers: {
      'content-type': 'application/octet-stream',
      //"Access-Control-Allow-Origin": "*"
    },
    timeout: 0, // important…
    onUploadProgress: progressCallback
  })
  return res.data
}
