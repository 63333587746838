import React from 'react'
import { useNavigate } from 'react-router-dom'

const FourOhFour: React.FC = function FourOhFour() {
  const navigate = useNavigate()
  const handleReturn = () => {
    navigate('/walks')
  }
  return (
    <div className="container mt-5">
      <div className="text-center text-white">
        <h1 className=''>Whoops…</h1>
        <p className='pt-3'>Spooky. There's nothing there.</p>
        <p className='pt-3'>What are you <em>really</em> looking for?</p>
        <button className="btn btn-primary mt-3" onClick={handleReturn}>Return to the domain of the living</button>
      </div>
    </div>
  )
}

export default FourOhFour
